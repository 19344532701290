.SubmitButton {
  background-color: rgba(30, 71, 94, 1);
  margin-top: 25px;
  padding: 12px 56px;
}

.SubmitButtonDiv {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.InputBox {
  margin: 10px;
}

.FormCard {
  width: 50vw;
  padding: 30px;
}

.Header {
  overflow: visible;
  width: 285px;
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  color: rgba(30, 71, 94, 1);
}

.FormContainer {
  margin: auto;
}

.ImageCol {
  background-color: rgba(30, 71, 94, 1);
}

.FormCol {
  text-align: right;
}

.Slider {
  align-items: center;
  width: 300px;
  height: 100%;
  margin: 0;
}

.ImageSize {
  width: inherit;
}

li {
  font-size: 12pt;
  color: black;
}

ul {
  margin: 0;
  padding: 0;
  padding-left: 15px;
}

h5 {
  text-align: center;
}

svg {
  width: 65%;
  height: auto;
  padding: 10px;
}

.PasswordFeedback {
  font-size: small;
  padding-left: 12px;
}

.notVis {
  display: none;
}
